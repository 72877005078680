import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/views/Home.vue';
import Regulations from '@/views/Regulations.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'root',
    // beforeEnter(to, from, next) {
    //   next(navigator.language);
    // },
    component: Home,
  },
  {
    path: '/regulations',
    name: 'regulations',
    component: Regulations,
  },
  {
    path: '/:lang',
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      // {
      //   path: 'about',
      //   name: 'about',
      //   component: About
      // }
    ],
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
  {
    path: '*',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

// beforeEnter(to, from, next) {
//   let lang = to.params.lang
//   if (languages.includes(lang)) {
//     if (store.state.locale !== lang) {
//       store.dispatch('changeLocale', lang)
//     }
//     return next()
//   }
//   return next({ path: store.state.locale })
// },
// children: [
//   {
//     path: '',
//     name: 'home',
//     component: Home
//   },
//   {
//     path: 'about',
//     name: 'about',
//     component: About
//   }
// ]
// }
